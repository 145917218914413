<template>
  <NuxtLoadingIndicator style="position: sticky;" />
  <div class="container-fluid">
    <HFunnelTopBar />
    <div class="row">
      <div
        class="left-column col-4 d-none d-md-block p-0"
      >
        <div
          ref="$map"
          class="map"
        />
      </div>

      <div class="right-column py-5 col-12 col-md-8">
        <div class="row">
          <div class="col-12 col-md-10 col-lg-7 offset-md-1">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { getCurrent } = useFunnel()
const { gMapLoader } = useGMap()

const topBarHeight = ref('')
const $map = ref()

useHead({
  bodyAttrs: {
    class: 'funnel'
  }
})

onMounted(() => {
  const $funnelNavBar = document.querySelector('.funnel-navbar')
  topBarHeight.value = $funnelNavBar?.scrollHeight + 'px'
})

watch(() => getCurrent('addresses').location, async (address) => {
  if (process.server) { return }

  const { Map } = await gMapLoader.importLibrary('maps')
  const { Marker } = await gMapLoader.importLibrary('marker')
  const position = { lat: address.coordinates[1], lng: address.coordinates[0] }

  const map = new Map($map.value as HTMLElement, {
    zoom: 19,
    disableDefaultUI: true,
    gestureHandling: 'none',
    center: position
  })

  // eslint-disable-next-line no-new
  new Marker({
    position,
    map,
    optimized: true
  })
}, { immediate: true })
</script>

<style lang="scss" scoped>
.left-column {
  position: sticky;
  top: v-bind(topBarHeight);
  height: calc(100vh - v-bind(topBarHeight));

  .map {
    height: 100%;
    width: 100%;
  }
}

:deep(.v-label) {
  &:not(.v-label--clickable, .v-field-label) {
    font-weight: bold;
  }
}

:deep(.v-radio-group > .v-input__control > .v-label) {
  margin-inline-start: unset !important;
}

</style>
